import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { IDataObject } from 'src/app/interfaces/generic/i-data-object';
import { HttpWebService } from '../http-web/http-web.service';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { URL_SERVICES } from 'src/app/constants/constants';
import { IFrontConfiguration } from 'src/app/interfaces/front-configuration/i-front-configuration';
import { IResponseWebService } from 'src/app/interfaces/response/i-response-web-service';
import { CAMPAIGN_BILLING, CONFIGURATIONS, FIDELITY, HEADER_UNIQUE, MESSAGE_MODAL, SECTIONS } from 'src/app/constants/keys';

@Injectable({ providedIn: 'root' })
export class FrontConfigService {

  constructor(private http: HttpWebService, private storage: LocalstorageService) {
    // Constructor vacio.
  }

  /**
   * Consulta e inicializa las configuraciones del proyecto.
   * @returns Observable.
   */
  public initializeConfig(): Observable<IDataObject> {

    const values: string[] = ["landing", "userActivity", "footer_unique", HEADER_UNIQUE, CAMPAIGN_BILLING, MESSAGE_MODAL, FIDELITY];

    const parameters: Map<string, string> = new Map([
      [SECTIONS, values.join(",")]
    ]);

    return this.http.get(URL_SERVICES.configurations, parameters)
      .pipe(

        map((response: IResponseWebService) => response.data),

        tap(
          (configurations: IFrontConfiguration[]) => {

            const frontConfigurations: any = configurations.reduce(
              (result: any, item: IFrontConfiguration) => {
                result[item.seccion] = item.data;
                return result;
              }, {});

            this.storage.save(CONFIGURATIONS, frontConfigurations);

          }));
  }

  /**
   * 
   * @param key Clave para recuperar configuracion.
   * @returns Datos.
   */
  public getParams(key: string): any {
    const configurations: any = this.storage.get(CONFIGURATIONS);
    return configurations[key] ?? null;
  }

}
