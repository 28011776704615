import { environment } from '../../environments/environment';

export const URL_SERVICES = {
    configurations: `${environment.HOST}front/configs`,
    urlConfiguratins: `${environment.HOST}login/configuraciones`,
    loginClient: `${environment.HOST}login/idp/validate_token_idp`,
    logoutClient: `${environment.HOST_SEC}login/session/logout`,
    banners: `${environment.HOST}promos-prod/sliders/PUBLIC_ONE`,
    brands: `${environment.HOST}points/paga/punto/sucursales/brands`,
    tokenEncode: `${environment.HOST_SEC}mgmnt/token/isclientencode`,
    legals: `${environment.HOST}cardstdclegal/`,
    frequentCuestion: `${environment.HOST}cuestions/`,
    campaing: {
        privateProductsBillingClient: `${environment.HOST_SEC}products/info/clients/[slug]`,
        privateSubscriptionClient: `${environment.HOST_SEC}promotions/subscriptions/[name]`,
        billing: `${environment.HOST}promotions/contents/all/value/[slug]/property/slug`,
        privateBillingClient: `/private/points/account_statement/private/billing?`,
        informative: `${environment.HOST}promotions/contents/all/value/[slug]/property/slug`,
        campaignSubscriptionsPublic: `${environment.HOST}promotions/subscriptions`,
        campaignSubscriptions: `${environment.HOST_SEC}promotions/subscriptions`,
    },
    campaingForm: {
        saveusercamp: `${environment.HOST}usrscampunique/saveusercamp`,
        findByPartyId: `${environment.HOST}usrscampunique/findByPartyId/`,
        findidCampaigAndPartyId:`${environment.HOST}usrscampunique/findidcampaigandparty/` 
    },
    homePublic: {
        homePublic: `${environment.HOST}homepublic/findAll`,
        pillar: `${environment.HOST}pilarpub/findIdPilar/`
    },
    blog: {
        blog: `${environment.HOST}bloguniq/findAll`,
        blogPilar: `${environment.HOST}blogpilar/`
    }
};