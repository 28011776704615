import { Injectable, OnInit } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as DeviceDetector from 'device-detector-js';

import { GTMPageViewInterface } from 'src/app/interfaces/gtm/gtm-page-view.interface';
import { GTMInteractionInterface } from 'src/app/interfaces/gtm/gtm-interaction.interface';

import { GTMPageViewConstant } from 'src/app/constants/gtm/gtm-page-view.constant';
import { GTMInteractionConstant } from 'src/app/constants/gtm/gtm-interaction.constant';

import { CheckTypeClass } from 'src/app/clases/check-type.class';
import { UtilsClass } from 'src/app/clases/utils.class';

// import *  as paquete from '../../../../package.json';
import *  as paquete from 'package.json';
import { AuthenticationService } from '../authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GtmService implements OnInit {

  /**
   * Inyección de dependencias e inicalización de atributos
   * @param googleTagManagerService gtmService
   */

  /**
   * Obtenemos los datos del dispositivo (Marca, Modelo, S.O)
   */
  deviceDetector = new DeviceDetector();
  dataParse = this.deviceDetector.parse(navigator.userAgent);
  routerUrl!: string;

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routerUrl = this.router.url;
  }

  /**
   * Ejecuta un tag en GTM para página vista
   * @param items Items
   * @param url que obtenemos desde el componente ejecutor
   */

   pushPageView(items: GTMPageViewInterface, url?: string): void {
    let urlGtm: string;
    
    try {
      // const url = new URL(window.location.href);
      this.routerUrl = this.router.url;
      url ? urlGtm = url : urlGtm = this.routerUrl;

      items = {
        ...GTMPageViewConstant,
        ...{
          tipoDispositivo: this.getDeviceType(),
          tipoSitio: this.authService.isAuthenticated() ? 'Privado' : 'Publico',
          userId: this.authService.getUserIDTag() || '',
          url: urlGtm,
          titulo: urlGtm.split('/').join('|'),
          marca_dispositivo: this.getBrandDevice(),
          sistema_operativo: this.getSystemOP(),
          versionApp: this.getVersionApp(),
        },
        ...items
      };

      items.section = this.generalFormatPageView(items.section, true);
      items.subsection1 = this.generalFormatPageView(items.subsection1, true);
      items.subsection2 = this.generalFormatPageView(items.subsection2, true);
      items.subsection3 = this.generalFormatPageView(items.subsection3, true);

      this.googleTagManagerService.pushTag(items);

    } catch (error) { }
  }

  /**
   * Ejecuta un tag en GTM para página vista
   * @param section
   * @param subsection1
   * @param subsection2
   * @param subsection3
   * @param url url parametrizada segun la necesidad
   */
   pageView(section: string, subsection1 = '', subsection2 = '', subsection3 = '', url?: string): void {
    this.pushPageView({
      section,
      subsection1,
      subsection2,
      subsection3
    }, url ? url : '');
  }

  /**
   * Ejecuta un tag en GTM para interaccón
   * @param items
   */
   pushInteraction(items: GTMInteractionInterface): void {
    try {
      items = {
        ...GTMInteractionConstant,
        ...{
          interaction_category: this.generalFormatInteraction(items.interaction_category),
          interaction_action: this.generalFormatInteraction(items.interaction_action),
          interaction_label: this.generalFormatInteraction(items.interaction_label),
          tipoProductos: this.generalFormatInteraction(items.tipoProductos),
          url: items.url
        }
      };

      this.googleTagManagerService.pushTag(items);

    } catch (error) { }
  }

  /**
   *
   * @param category
   * @param action
   * @param label
   */
   interaction(category: string, action: string, label: string, producto?: string, url?: string): void {
    let urlGtm: string;
    this.routerUrl = this.router.url;
    url ? urlGtm = url : urlGtm = this.routerUrl;
    this.pushInteraction({
      interaction_category: category,
      interaction_action: action,
      interaction_label: label,
      tipoProductos: producto,
      url: urlGtm,
    });
  }

  /**
   * Asiganción de formato para vista de página
   * @param item
   * @returns
   */
   generalFormatPageView(item = '', upperFirstChart = false): string {

    if (item && CheckTypeClass.isString(item)) {
      /**
       * Quitar espacios inicio y fin
       */
      item = item.trim();
      /**
       * Eliminación de tildes
       */
      item = UtilsClass.removeAccents(item);
      /**
       * Cambio de '-' por '_'
       */
      item = item.split('-').join('_');
      /**
       * Cambio de ' ' por '_'
       */
      item = item.split(' ').join('_');
      /**
       * Elimina carateres especiales
       */
      item = UtilsClass.removeSpecialCharacters(item);
      /**
       * Primera letra mayuscula
       */
      if (upperFirstChart) {
        item = item.split('_').map(word => UtilsClass.upperFirst(word)).join('_');
      }

    }

    return item;
  }

  /**
   * Asignación de formato para interacción
   * @param item
   * @returns
   */
   generalFormatInteraction(item = ''): string {

    if (item && CheckTypeClass.isString(item)) {
      /**
       * Quitar espacios inicio y fin
       */
      item = item.trim();
      /**
       * Eliminación de tildes
       */
      item = UtilsClass.removeAccents(item);
      /**
       * Elimina carateres especiales
       */
      item = UtilsClass.removeSpecialCharacters(item);
      /**
       * Todo a minusculas
       */
      item = item.toLowerCase();
      /**
       * Cambio de '-' por '_'
       */
      item = item.split('-').join('_');
      /**
       * Cambio de ' ' por '_'
       */
      item = item.split(' ').join('_');
    }

    return item;
  }

  /**
   * REgresa el nombre del dispositivo
   * @returns
   */
   getDeviceType(): string {
    try {
      return this.dataParse?.device?.type || 'desktop';
    } catch (error) {
      return 'desktop';
    }
  }

  /**
   * 
   * @returns version de la aplicacion
   */
  getVersionApp() {
    const pkg: any = JSON.parse(JSON.stringify(paquete));
    return pkg.version;
  }

  /**
   * 
   * @returns retorna la marca y el modelo del dispositivo
   */
  getBrandDevice(){
    try {
      let brand = `${this.dataParse?.device?.brand}-${this.dataParse?.device?.model}`;
      let cleanBrand = brand.replace(/\s/g, '-');
      return cleanBrand || 'Windows';
    } catch (error) {
      return 'Windows';
    }
  }

  /**
   * 
   * @returns retorna el sistema operativo del dispositivo
   */
  getSystemOP() {
    try {
      return this.dataParse?.os?.name || 'Android';
    } catch (error) {
      return 'Android';
    }
  }
}
