const STATUS_LOGIN: string = "status_login";
const NAME: string = "name";
const PBWC: string = "pbwc";
const LAST_LOGIN: string = "lastLogin";
const SIGNED_IN: string = "SIGNED_IN";
const SIGNED_OUT: string = "SIGNED_OUT";
const SESSION: string = "SESSION";
const USERIDTAG: string = "USERIDTAG";
const GATEWAY_TOKEN: string = "gateway_token";
const CONFIGURATIONS: string = "configurations";
const SECTIONS: string = "secciones";
const URL_CONFIG = "url_config";
const TRY_TO_INSCRIPTION = "tryToInscription";
const EXTERNAL_LINK = "campaign_detail_santander";
const NEW_LINE: string = "[NEW_LINE]";
const HEADER_UNIQUE: string = "header_unique";
const HOME_PUBLIC: string = 'home_public';
const CAMPAIGN: string = 'campaign';
const FOOTER_UNIQUE: string = 'footer_unique';
const MODAL_SUCCES: string = "modal_succes";
const MODAL_GENERIC_ERROR: string = "modal_error";
const MODAL_CARD_ERROR: string = "modal_card_error"
const CAMPAIGN_BILLING: string = "campaign_billing"
const MESSAGE_MODAL: string = "message_modal";
const FIDELITY: string = "fidelity";
const OPEN_DIALOG_FIDELITY: string = "open";
const OPEN_DIALOG_NOT_FIDELITY: string = "open_not_fidelity";
const CLOSE_DIALOG: string = "close";
const IDSECTIONFIDELITY: string = 'idSectionFidelity';
const URLREDIRECTIDP: string = 'urlRedirectIDP';
const REDIRECT: string = 'redirect';

export {
    STATUS_LOGIN,
    NAME,
    PBWC,
    LAST_LOGIN,
    SIGNED_IN,
    SIGNED_OUT,
    SESSION,
    USERIDTAG,
    GATEWAY_TOKEN,
    CONFIGURATIONS,
    SECTIONS,
    URL_CONFIG,
    TRY_TO_INSCRIPTION,
    EXTERNAL_LINK,
    NEW_LINE,
    HEADER_UNIQUE,
    HOME_PUBLIC,
    CAMPAIGN,
    FOOTER_UNIQUE,
    MODAL_SUCCES,
    MODAL_GENERIC_ERROR,
    MODAL_CARD_ERROR,
    CAMPAIGN_BILLING,
    MESSAGE_MODAL,
    FIDELITY,
    OPEN_DIALOG_FIDELITY,
    OPEN_DIALOG_NOT_FIDELITY,
    CLOSE_DIALOG,
    IDSECTIONFIDELITY,
    URLREDIRECTIDP,
    REDIRECT,
};
