import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GATEWAY_TOKEN } from 'src/app/constants/keys';
import { IGatewayToken } from 'src/app/interfaces/gateway-token/i-gateway-token';
import { IResponseWebService } from 'src/app/interfaces/response/i-response-web-service';
import { environment } from 'src/environments/environment';
import { HttpWebService } from '../http-web/http-web.service';
import { LocalstorageService } from '../localstorage/localstorage.service';

@Injectable({ providedIn: 'root' })
export class GatewayTokenService {
  /**
   * gateway token object
   */
  private gatewayToken: IGatewayToken;

  /**
   * Inyección de dependencias e inicalización de atributos
   * @param localStorageService service to estore data in local storage
   * @param httpWebService service to do http requests
   */
  constructor(
    private localStorageService: LocalstorageService,
    private httpWebService: HttpWebService) {
    this.gatewayToken = localStorageService.get(GATEWAY_TOKEN);
  }

  /**
   * Returns access token
   */
  getGatewayToken(): string {
    return this.gatewayToken ? this.gatewayToken.access_token : "";
  }

  /**
   * request for new gateway token
   */
  refreshGatewayToken(): Observable<IGatewayToken> {
    return this.httpWebService.post(`${environment.token}`)
      .pipe(

        map((httpResponse: IResponseWebService) => httpResponse.data),

        map((response: any) => {
          this.gatewayToken = response;
          this.localStorageService.save(GATEWAY_TOKEN, this.gatewayToken);
          return this.gatewayToken;
        })
      )
  }
}
