import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ButtonBanner, IModalPublic } from 'src/app/interfaces/dialog-modal/dialog.model';
import { BannerUtils } from 'src/app/clases/banner-static/banner-utils';
import { defaultModalHome } from 'src/app/services/home-public/home-public.config';
import { GtmService } from 'src/app/services/googletagmanager/gtm.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'unique-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

  public show: boolean = false;

  public bgImage: string = '';
  public isLogin: boolean = false;

  @Input()
  public data: Partial<IModalPublic> = {};

  public content: IModalPublic = defaultModalHome;

  constructor(
    private gtmSrv: GtmService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    // Constructor vacio.
  }

  ngOnInit(): void {
    this.content = this.data as IModalPublic;
    if (this.content.status) {
      this.show = true;
      this.getStatusLogin();
      document.body.classList.add('overflow-hidden');
      this.initConf();
    }
    let routeNavigate = this.router.url;
    let splitRoute = routeNavigate.split('/');
    this.gtmSrv.pageView(splitRoute[1], "modal_"+this.content.type);
  
  }

  ngOnDestroy() {
    document.body.classList.remove('overflow-hidden');
   
  }

  private initConf(): void {
    if (this.content.type === 'IMG') {
      this.setBackgroundImage();
    }
  }

  public closeModal(): void {
    this.goOnClickTaggeo()
    this.show = false;
    document.body.classList.remove('overflow-hidden');
    const volume= document.getElementById('miVideo') as HTMLMediaElement
    if (volume ){
      volume.pause()
    }
  }

  /**
   * Metodo para realizar redirect a paginas externas.
   * @param evt Datos de pagina.
   */
  public goOnClick(evt: ButtonBanner): void {
    if (evt.target === '_self') {
      this.goOnClickTaggeo()
      window.location.href = evt.destination;
    } else {
      this.closeModal()
      window.open(evt.destination);
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.content.type === 'IMG') {
      this.setBackgroundImage();
    }
  }

  /**
   * Establece la imagen del acuerdo al tamaño de la pantalla.
   */
  private setBackgroundImage(): void {
    this.bgImage = BannerUtils.getBackgroundImage(window.innerWidth, this.content.bgImages);
  }

  public goOnClickTaggeo() {
    let banner;
    switch (this.content.type) {
      case 'IMG':
        banner = "baner_" + this.cutName(this.bgImage);
        break;
      case 'VIDEO':
        banner = this.content.type + "_" + this.cutName(this.content.urlVideo)
          ;
        break;
      case 'GIF':
        banner = this.content.type + "_" + this.cutName(this.content.urlGif)
    }
    this.gtmSrv.interaction(
      'unique rewards',
      'seccion_' + banner,
      this.content.button.text,
      this.content.title
    );
  }
  
  cutName(name: string) {
    let indiceInicio = name.lastIndexOf("/") + 1
    let nombreArchivoConExtension = name.substring(indiceInicio, name.lastIndexOf("."));
    return nombreArchivoConExtension
  }

  private getStatusLogin() {
    this.isLogin = this.authService.isAuthenticated();
  }

}
