<div class="modal" [ngStyle]="{'display': show ? 'block': 'none'}">

    <div class="modal-content slide-bottom" *ngIf="isUnique">
        <div class="modal-body margin">
            <img alt="Santander Unique" class="icon-unique" src="/public/sources/logo_santander_unique_rewards.png">
            <p class="title">
                <strong>{{dialogUnique.title}}</strong>
            </p>
            <p class="description">
                {{dialogUnique.description}}
            </p>
        </div>

    </div>

    <div class="modal-content slide-bottom" *ngIf="!isUnique">
        <div class="modal-header">
            <img src="assets/icons/closeIcon.png" alt="close" (click)="closeDialog()" />
        </div>
        <div class="modal-body margin-bottom">
            <p class="title">
                <strong>{{dialogNotUnique.title}}</strong>
            </p>
            <p class="description">
                {{dialogNotUnique.description}}
            </p>
            <a class="btn_primary m-bottom" href={{dialogNotUnique.url}}>{{dialogNotUnique.textButton}}</a>
        </div>
    </div>

</div>