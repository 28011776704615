import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PrivateHttpInterceptor } from './private-http.interceptor';
import { PublicHttpInterceptor } from './public-http.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PrivateHttpInterceptor,
      multi: true
    }
    /**
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockResponsesInterceptor,
      multi: true
    }
     */

  ]
})
export class InterceptorModule { }
