import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ILocalStorage } from './i-local-storage';

@Injectable({ providedIn: 'root' })
export class LocalstorageService implements ILocalStorage {

  private lsske: string = "cGFzc3dvcmRfZW5jcmlwdGFkb19wYXJhX3F1ZV9ub19zZV9xdWVqZV9jaXNv";

  private production: boolean = environment.production;

  constructor() {
    /* TODO document why this constructor is empty */
  }

  /**
   * Guarda informacion con clave valor.
   * 
   * @param key Clave del valor.
   * @param data Valor a guardar.
   */
  public save(key: string, data: any): void {
    if (this.production) {
      const encryptedKey: string = this.encryptKey(key);
      const encryptedData: string = this.encryptData(data);
      localStorage.setItem(encryptedKey, encryptedData);
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  /**
   * Recupera el valor correspondiente a la clave recibida.
   * 
   * @param key Clave del valor.
   * @returns Valor de la clave.
   */
  public get(key: string): any {
    if (this.production) {
      const encryptedKey: string = this.encryptKey(key);
      const data: any = localStorage.getItem(encryptedKey);
      return this.decrypt(data) ?? null;
    } else {
      const data: any = localStorage.getItem(key);
      try {
        return JSON.parse(data);
      } catch (e) {
        return null;
      }
    }
  }

  /**
   * Reinicia el valor de la propiedad indicada.
   * 
   * @param key Clave que se requiere reiniciar.
   */
  public resetProperty(key: string): void {
    if (this.production) {
      const encryptedKey: string = CryptoJS.SHA256(key).toString();
      localStorage.removeItem(encryptedKey);
    } else {
      localStorage.removeItem(key);
    }
  }

  /**
   * Re inicia todo el localstorage.
   */
  public reset(): void {
    localStorage.clear();
  }

  /**
   * Encripta la informacion recibida.
   * 
   * @param data Datos que seran encriptados.
   * @returns Datos encryptados.
   */
  private encryptData(data: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.lsske).toString();;
  }

  /**
   * Ecrypta la clave indicada.
   * @param key clave que sera encryptada.
   * @returns Clave encryptada.
   */
  private encryptKey(key: string): string {
    return CryptoJS.SHA256(key).toString();
  }

  /**
   * Desencripta informacion.
   */
  private decrypt(data: any | ''): any {
    const dencryptedData: string = CryptoJS.AES.decrypt(data ?? "", this.lsske).toString(CryptoJS.enc.Utf8);
    try {
      return JSON.parse(dencryptedData);
    } catch (e) {
      return null;
    }
  }
}
