import { NgModule } from '@angular/core';
import { UniqueFrontRoutingModule } from './unique-front-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { UniqueFrontComponent } from './unique-front/unique-front.component';
import { InitializerModule } from '../providers/initializer.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { InterceptorModule } from '../interceptor/interceptor.module';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    UniqueFrontComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    UniqueFrontRoutingModule,
    HttpClientModule,
    InitializerModule,
    InterceptorModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.GOOGLE.TAG_MANAGER_ID }
  ],
  bootstrap: [UniqueFrontComponent]
})
export class UniqueFrontModule { }
