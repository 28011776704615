import { DataDialog } from "src/app/interfaces/messageModal/messageModal.model";

export const initDialogFidelity: DataDialog = {
    title: 'Estás entrando a Santander Unique Rewards',
    description: 'Te dirigiremos a un sitio externo a Santander, el cual mantiene sus propias políticas de seguridad y privacidad.',
    textButton: '',
    url: '',
    showButton: false,
    time: 2000
};

export const initDialogNotFidelity: DataDialog = {
    title: 'Espera',
    description: 'Santander Unique Rewards es un beneficio exclusivo para clientes Unique. Contrata una tarjeta participante para tener acceso al mundo Unique.',
    textButton: 'Ver tarjetas participantes',
    url: 'https://www.uniquerewards.santander.com.mx/',
    showButton: true,
    time: 0
};