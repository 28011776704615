

<section class="modal" [ngClass]="abriModal ? 'modal_show' : 'modal'" *ngIf="dataModal">
    <div [ngClass]="isTyc ? 'modal_container_tyc' : 'modal_container' ">
        <div class="icon_close">
            <img src="assets/icons/closeIcon.png" alt="close" (click)="justCloseModal(false)">
        </div>
        <div [ngClass]="isTyc ? 'container_icon_tyc' : 'container_icon' ">
            <img *ngIf="dataModal.icon && dataModal.nombreIcon" [src]="dataModal.icon" [alt]="dataModal.nombreIcon">
        </div>
        <h2 [ngClass]="isTyc ? 'modal_title_tyc' : 'modal_title'" [innerHTML]="dataModal.title"></h2>
        <div class="modal_container_text">
            <p class="modal_paragraph" [innerHTML]="dataModal.subtitle"></p>
        </div>
        <div class="container_actions_btn">
            <a class="modal_close" (click)="closeModal(false)"> {{ dataModal.textBtn }} </a> 
        </div>
    </div>
</section>

