import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject, takeUntil, TimeoutError } from 'rxjs';
import { IResponseWebService } from 'src/app/interfaces/response/i-response-web-service';

@Injectable({ providedIn: 'root' })
export class HttpWebService {

  private stop$ = new Subject<void>();

  constructor(private http: HttpClient) {
    // Constructor vacio.
  }

  /**
   * 
   * @param url url de la petición.
   * @param params Map con parametros.
   * @param headers Map con headers.
   * @returns Observable.
   */
  public get(destiny: string, params: Map<string, string> = new Map(), headers: Map<string, string> = new Map()): Observable<any> {
    return this.http.get<HttpResponse<IResponseWebService>>(encodeURI(destiny),
      {
        headers: this.mapHeaders(headers),
        params: this.mapParams(params),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      })
      .pipe(takeUntil(this.stop$), map(this.mapResponse));
  }

  /**
   * 
   * @param url Url de la peticion.
   * @param body Parametros enviados.
   * @param headers Headers adicionales.
   * @returns Observable.
   */
  public post(url: string, body: Map<string, string> = new Map(), headers: Map<string, string> = new Map(), params: Map<string, string> = new Map()): Observable<any> {
    const jsonBody: string = JSON.stringify(Object.fromEntries(body));
    return this.http.post<HttpResponse<IResponseWebService>>(url,
      jsonBody,
      {
        headers: this.mapHeaders(headers),
        params: this.mapParams(params),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      })
      .pipe(map(this.mapResponse));
  }

  /**
 * 
 * @param url Url de la peticion.
 * @param body Parametros enviados.
 * @param headers Headers adicionales.
 * @returns Observable.
 */
  public postRaw(url: string, body: Map<string, string> = new Map(), headers: Map<string, string> = new Map(), params: Map<string, string> = new Map()): Observable<any> {
    const jsonBody: string = JSON.stringify(Object.fromEntries(body));
    return this.http.post<HttpResponse<IResponseWebService>>(encodeURI(url),
      jsonBody,
      {
        headers: this.mapHeaders(headers),
        params: this.mapParams(params),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      });
  }

  public postSimple(url: string, body: any = {}, headers: Map<string, string> = new Map(), params: Map<string, string> = new Map()): Observable<any> {
    return this.http.post<HttpResponse<IResponseWebService>>(url,
      body,
      {
        headers: this.mapHeaders(headers),
        params: this.mapParams(params),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      })
      .pipe(map(this.mapResponse));
  }

  /**
 * 
 * @param url Url de la peticion.
 * @param body Parametros enviados.
 * @param headers Headers adicionales.
 * @returns Observable.
 */
  public postWithRawData(url: string, body: Map<string, string> = new Map(), headers: Map<string, string> = new Map(), params: Map<string, string> = new Map()): Observable<any> {
    const jsonBody: string = JSON.stringify(Object.fromEntries(body));
    return this.http.post<IResponseWebService>(url,
      jsonBody,
      {
        headers: this.mapHeaders(headers),
        params: this.mapParams(params),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      });
  }

  /**
   * 
   * @param url Url de la peticion. 
   * @param id Id de la peticin.
   * @param body Parametros enviados.
   * @param headers Headers adicionales.
   * @returns Observable.
   */
  public put(url: string, id: number | string = "", body: Map<string, string> = new Map(), headers: Map<string, string> = new Map()): Observable<any> {
    return this.http.put<HttpResponse<IResponseWebService>>(
      this.formatterUrl(url, id),
      body,
      {
        headers: this.mapHeaders(headers),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      }
    ).pipe(map(this.mapResponse));
  }

  private formatterUrl(url: string, id: number | string = ""): string {
    if (typeof id === 'number' || id.length > 0) {
      return `${url}/${id}`;
    }
    else {
      return url;
    }
  }

  /**
   * 
   * @param url Url de la peticion.
   * @param headers Headers adicionales.
   * @returns Observable.
   */
  public delete(url: string, headers: Map<string, string> = new Map()): Observable<any> {
    return this.http.delete<HttpResponse<IResponseWebService>>(
      url,
      {
        headers: this.mapHeaders(headers),
        observe: 'response',
        responseType: 'json',
        reportProgress: false,
        withCredentials: false
      }).pipe(map(this.mapResponse));
  }

  /**
 * 
 * @param headers Map con headers.
 * @returns HttpHeaders con headers para consulta.
 */
  private mapHeaders(headers: Map<string, string>): HttpHeaders {
    let httpHeaders: HttpHeaders = new HttpHeaders().append('Content-Type', 'application/json');
    for (const [key, value] of headers) {
      httpHeaders = httpHeaders.append(key, value);
    }
    return httpHeaders;
  }

  /**
   * 
   * @param params Map con parametros a enviar.
   * @returns HttpParams con parametros de consulta.
   */
  private mapParams(params: Map<string, string>): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    for (let [key, value] of params) {
      httpParams = httpParams.append(key, value);
    }
    return httpParams;
  }

  /**
   * 
   * @param httpResponse Respuesta de la peticion.
   * @returns Response.
   */
  private mapResponse(httpResponse: HttpResponse<any>): IResponseWebService {
    if (typeof httpResponse.status === 'boolean') {
      const response: any = httpResponse;
      return response?.data;
    }
    else if (httpResponse.status && httpResponse.status === 200) {
      return {
        data: httpResponse.body?.data ? httpResponse.body?.data : httpResponse.body?.listData,
        folio: httpResponse.body?.folio,
        message: httpResponse.body?.message,
        status: httpResponse.body?.status,
        statuscode: httpResponse.status
      };
    } else {
      return {
        data: null,
        folio: '',
        message: '',
        status: false,
        statuscode: httpResponse.status
      };
    }
  }

  /**
   * 
   * @param error error a validar.
   */
  private handleError(error: any): any {
    if (error instanceof TimeoutError) {
      throw (new HttpErrorResponse({
        error: {
          folio: '-1',
          message: 'Se ha agotado el tiempo de espera'
        }
      }));
    }
    throw error;
  }

  private stop(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}