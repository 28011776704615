<div class="modal" [ngStyle]="{'display': (show && !isLogin) ? 'grid': 'none'}">

    <!-- Modal con validacion de dimension a mostrar -->
    <div class="modal-content slide-bottom" [ngClass]="content.extraClass">
        <div class="modal-header">
            <img src="assets/icons/closeIcon.png" alt="close" (click)="closeModal()" />
        </div>
        <div class="modal-body">

            <p class="title">{{content.title}}</p>

            <div class="description" [innerHTML]="content.subtitle"></div>

            <span [ngSwitch]="content.button.type">
                <p *ngSwitchCase="'EXTERNAL'">
                    <button class="btn_primary button"
                        (click)="goOnClick(content.button)">{{content.button.text}}</button>
                </p>
                <p *ngSwitchCase="'INTERNAL'">
                    <a class="btn_primary button" [routerLink]="content.button.destination"
                    (click)="goOnClickTaggeo()" target={{content.button.target}}>{{content.button.text}}</a>
                </p>
                <p *ngSwitchCase="'CLOSE'">
                    <button class="btn_primary button" (click)="closeModal()">{{content.button.text}}</button>
                </p>
            </span>

            <span [ngSwitch]="content.type">
                <p *ngSwitchCase="'VIDEO'" class="padding-video">
                    <video class="w-100" id="miVideo"oncanplay="this.play()" onloadedmetadata="this.muted = true" playsinline 
                        controls (ended)="closeModal()">
                        <source [src]="content.urlVideo" type="video/mp4" />
                    </video>
                </p>
                <p *ngSwitchCase="'GIF'">
                    <img [src]="content.urlGif" alt="image banner" class="bg-banner-image-home">
                </p>
                <p *ngSwitchCase="'IMG'">
                    <img [src]="bgImage" alt="image banner" class="bg-banner-image-home">
                </p>
            </span>


        </div>
    </div>

</div>