export interface IResponseWebService {

    data: any;

    folio: string;

    message: string;

    status: boolean;

    statuscode: number;

}


export interface IResponseWebUnique {

    respStatus: Record<string, string>;

    data: any;

}

export interface SecObjInfo {

    SecObjValue: string;

    SecObjPurpose: string;

}

export interface SecObjRec {

    SecObjInfo: SecObjInfo

}

export interface ResponseFidelityToken {

    SecObjRec: SecObjRec

}