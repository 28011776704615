import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UniqueFrontModule } from './app/main/unique-front.module';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  /* 
  con la línea de código a continuacion eliminamos los console.log en producción
  */
  window.console.log = () => {}
}

platformBrowserDynamic().bootstrapModule(UniqueFrontModule)
  .catch(err => console.error(err));
