import { Component, OnInit } from '@angular/core';
import { CLOSE_DIALOG, FIDELITY, MESSAGE_MODAL, OPEN_DIALOG_FIDELITY, OPEN_DIALOG_NOT_FIDELITY, SIGNED_OUT, STATUS_LOGIN } from 'src/app/constants/keys';
import { DataDialog } from 'src/app/interfaces/messageModal/messageModal.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DatasourceService } from 'src/app/services/datasource/datasource.service';
import { FrontConfigService } from 'src/app/services/front-config/front-config.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { initDialogFidelity, initDialogNotFidelity } from './dialog.config';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'unique-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public dialogUnique: DataDialog = initDialogFidelity;

  public dialogNotUnique: DataDialog = initDialogNotFidelity;

  public show: boolean = false;

  public isUnique: boolean = true;

  constructor(
    private storage: LocalstorageService,
    private source: DatasourceService,
    private authService: AuthenticationService,
    private frontConfig: FrontConfigService,
    private alerSrv: AlertService
  ) {

    source.changes.subscribe((changes: Map<string, any>) => {
      const status: string = changes.get(FIDELITY);
      if (status && status.length > 0) {
        this.show = true;
        if (status === OPEN_DIALOG_FIDELITY) {
          this.isUnique = true;
          const timer = setTimeout(() => {
            this.confirm();
            clearTimeout(timer);
          }, this.dialogUnique.time);
        } else if (status === OPEN_DIALOG_NOT_FIDELITY) {
          this.isUnique = false;
        }
        else {
          this.show = false;
        }
      }
    });

  }

  ngOnInit(): void {
    this.dialogUnique = this.frontConfig.getParams(MESSAGE_MODAL).dialogFidelity ?? initDialogFidelity;
    this.dialogNotUnique = this.frontConfig.getParams(MESSAGE_MODAL).dialogNotFidelity ?? initDialogNotFidelity;
  }

  public closeDialog(): void {
    this.source.propagate(FIDELITY, CLOSE_DIALOG);
    this.source.propagate(STATUS_LOGIN, SIGNED_OUT);
    this.alerSrv.setCloseFidelity = false;
  }

  public confirm(): void {
    this.source.propagate(FIDELITY, CLOSE_DIALOG);
    this.authService.confirmRedirectToFidelity();
  }

}
