import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, catchError, finalize, Observable, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from '../services/localstorage/localstorage.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { GatewayTokenService } from '../services/gateway-token/gateway-token.service';
import { SESSION } from '../constants/keys';

@Injectable({ providedIn: 'root' })
export class PrivateHttpInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  invalidTokenCounter = 0;

  constructor(
    private gatewayTokenService: GatewayTokenService,
    private storage: LocalstorageService,
    private authentication: AuthenticationService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.checkUrl(req)) {
      req = this.addAuthHeaders(req);

      return next.handle(req)
        .pipe(
          catchError((errorResponse: HttpErrorResponse) => {
            const error = (typeof errorResponse !== 'object') ? JSON.parse(errorResponse) : errorResponse;
            if (error.status === 401) {
              if (error.error.message === 'SESSION_INVALID') { //  || error.error.error === 'oauth_server_error'
                return this.localLogout(error);
              } else {
                return this.handle401Error(req, next);
              }
            }
            return throwError(error);
          })
        );
    } else {
      return next.handle(req);
    }
  }

  private checkUrl(req: HttpRequest<any>) {
    return req.url.includes(environment.HOST_SEC) && req.url !== environment.token;
  }

  private addAuthHeaders(request: HttpRequest<unknown>) {
    const accessToken = this.gatewayTokenService.getGatewayToken();
    if (!accessToken) {
      return request;
    }
    return request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.storage.get(SESSION) ?? 'EGMC',
        'Authorization': `Bearer ${accessToken}`
      })
    });
  }

  private localLogout(err: any) {
    this.authentication.logoutToken().then().finally(() => { });
    return throwError(err);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.gatewayTokenService.refreshGatewayToken().pipe(
      switchMap((t: any) => {
        return next.handle(this.addAuthHeaders(request));
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(null);
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      })
    );
  }

}
