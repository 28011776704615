import { GTMPageViewInterface } from 'src/app/interfaces/gtm/gtm-page-view.interface';
/**
 * Datos default par taggear una PageView
 */
export const GTMPageViewConstant: GTMPageViewInterface = {
    canalBanco: 'Loyalty',
    idiomaPagina: 'Espanol',
    tipoDispositivo: 'desktop',
    tipoSitio: 'Publico',
    url: '',
    titulo: '',
    section: '',
    subsection1: '',
    subsection2: '',
    subsection3: '',
    // asunto: '',
    event: 'pageView',
};
