import { Component } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'unique-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent {

  public menuBurgerDisplayed!: boolean;

  constructor( private menuHamburgerSrv: AlertService ) {
    this.getStatusMenuHamburger();
  }

  getStatusMenuHamburger(){
    this.menuHamburgerSrv.getMenuSubject.subscribe( (rspMenu: boolean) => {
      this.menuBurgerDisplayed = rspMenu;
    })
  }

}
