import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { GatewayTokenService } from '../services/gateway-token/gateway-token.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PublicHttpInterceptor implements HttpInterceptor {

  private isRefreshingToken: boolean = false;

  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private gatewayTokenService: GatewayTokenService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.checkUrl(request)) {
      request = this.addAuthorizationHeaders(request);
      return next.handle(request).pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          const error = (typeof errorResponse !== 'object') ? JSON.parse(errorResponse) : errorResponse;
          if (error.status === 401) {
            return throwError(error);
          }
          return throwError(error);
        })
      );
    }
    return next.handle(request);
  }

  private checkUrl(request: HttpRequest<any>): boolean {
    return request.url.includes(environment.HOST) && request.url !== environment.token;
  }

  private addAuthorizationHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken = this.gatewayTokenService.getGatewayToken();
    if (!accessToken) {
      return request;
    }

    return request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${accessToken}`
      })
    });
  }

}
