<!-- Header -->
<header class="bg-principal container-all-width" [ngClass]="menuBurgerDisplayed ? 'container_header_fixed' : 'container_header_static' ">
    <unique-header></unique-header>
</header>

<!-- Router -->
<div class="container-all-width">

    <!-- TODO Loader que nos puede ayudar para la carga de datos y/o proceso de redireccionamiento al sitio privado -->
<!-- <div class="organic" *ngIf="false">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <img class="loader-logo" src="assets/imgs/idp/loader.svg" alt="loader santander">
</div> -->
    <router-outlet></router-outlet>
</div>

<!-- Footer -->
<footer class="bg-principal container-all-width">
    <unique-footer></unique-footer>
</footer>

<unique-dialog></unique-dialog>