import { PointsTerms, } from "src/app/interfaces/footer/footer.model";


export const termsConditionsDummy: PointsTerms = {
    "title": "Acerca de",
    "list": [
        {
            "title": "Términos y condiciones",
            "idSectionFidelity": "",
            "btnBanner": {
                "type": "INTERNAL",
                "destination": "/legal/termsAndConditions?type=terms-and-conditions",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Aviso de Privacidad",
            "idSectionFidelity": "",
            "btnBanner": {
                "type": "INTERNAL",
                "destination": "/legal/termsAndConditions?type=notice-of-privacy",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Preguntas Frecuentes",
            "idSectionFidelity": "1882|31786",
            "btnBanner": {
                "type": "",
                "destination": "",
                "extra": "",
                "target": "",
                "extraField": ""
            }
        },
        {
            "title": "Obtén tu tarjeta",
            "idSectionFidelity": "",
            "btnBanner": {
                "type": "EXTERNAL",
                "destination": "https://www.uniquerewards.santander.com.mx/oferta-de-tarjetas-public.php",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        }
    ]
}

export const pointsDummy: PointsTerms = {
    "title": "Usa tus puntos",
    "canShowPoints": true,
    "list": [
        {
            "title": "Unique Events",
            "idSectionFidelity": "",
            "btnBanner" : {
                "type": "EXTERNAL",
                "destination": "https://www.uniquerewards.santander.com.mx/oferta-de-tarjetas-public.php",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Unique Travel",
            "idSectionFidelity": "",
            "btnBanner" : {
                "type": "INTERNAL",
                "destination": "/home/pillar/unique_travel",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Unique Dining",
            "idSectionFidelity": "1882|31786",
            "btnBanner" : {
                "type": "",
                "destination": "",
                "extra": "",
                "target": "",
                "extraField": ""
            }
        },
        {
            "title": "Unique Lifestyle",
            "idSectionFidelity": "",
            "btnBanner" : {
                "type": "INTERNAL",
                "destination": "/home/pillar/unique_lifestyle",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Unique Donations",
            "idSectionFidelity": "",
            "btnBanner" : {
                "type": "INTERNAL",
                "destination": "/home/pillar/unique_donations",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        },
        {
            "title": "Compra Unique Points",
            "idSectionFidelity": "",
            "btnBanner" : {
                "type": "INTERNAL",
                "destination": "/home/pillar/compra_unique_points",
                "extra": "",
                "target": "_self",
                "extraField": ""
            }
        }
    ]
}