import { CheckTypeClass } from "./check-type.class";

/**
 * Clase que contiene métodos diversos para manejo de cadenas y dar formato
 */
const INFINITY = 1 / 0;
const MAX_INTEGER = 1.7976931348623157e+308;

export class UtilsClass {

  /**
   * toDecimal
   * @param value Valor
   * @param decimal Número de decimales
   */
  static toDecimal(value: number, decimal: number): number {
    return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  /**
   * upperFirst
   * @param value Valor
   */
  static upperFirst(value: string): string {
    return value.slice(0, 1).toUpperCase() + value.slice(1);
  }

  /**
   * leftPad
   * @param value Valor
   * @param _length Longitud
   * @param ch CH
   */
  static leftPad(value: string, _length: number = 0, ch: any = ' ') {
    value = String(value);
    ch = ch.toString();
    let i = -1;
    const length = _length - value.length;

    while (++i < length && (value.length + ch.length) <= _length) {
      value = ch + value;
    }

    return value;
  }

  /**
   * rightPad
   * @param value Valor
   * @param _length Longitud
   * @param ch CH
   */
  static rightPad(value: string, _length: number = 0, ch: any = ' ') {

    value = String(value);
    ch = ch.toString();

    let i = -1;
    const length = _length - value.length;

    while (++i < length && (value.length + ch.length) <= _length) {
      value += ch;
    }

    return value;
  }

  /**
   * pad
   * @param value Valor
   * @param _length Longitud
   * @param ch CH
   */
  static pad(value: string, _length: number = 0, ch: any = ' '): string {
    value = String(value);
    ch = ch.toString();
    let i = -1;
    const length = _length - value.length;


    let left = true;
    while (++i < length) {

      const l = (value.length + ch.length <= _length) ? (value.length + ch.length) : (value.length + 1);

      if (left) {
        value = this.leftPad(value, l, ch);
      } else {
        value = this.rightPad(value, l, ch);
      }

      left = !left;
    }

    return value;
  }

  /**
   * Joins a string with underscores
   * @param str string to format
   */
  static joinUnderscore(str: string): any {
    let val: string = str.split(' ').join('_');
    val = val.replace(/_{2,}/g, '_');

    return this.removeAccents(val);
  }

  /**
   * Joins a string with scores
   * @param str string to format
   */
  static joinMiddleScores(str: string): any {
    let val: string = str.split(' ').join('-');
    val = val.replace(/-{2,}/g, '-');

    return this.removeAccents(val);
  }

  /**
   * Capitalize first letter in s astring
   * @param str string to format
   */
  static capitilize(str: string): any {

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Removes accents
   * @param str string to format
   */
  static removeAccentsComplement(str: string, accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž', accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz') {
    if (str && typeof str === 'string') {
      let strAccents: string[] = str.split('');
      let strAccentsOut: string[] = strAccents;
      let strAccentsLen = strAccents.length;
      for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) !== -1) {
          strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else {
          strAccentsOut[y] = strAccents[y];
        }
      }
      return strAccentsOut.join('');
    }
    return '';
  }
  /**
   * Remove accents
   * @param str
   * @returns 
   */
  static removeAccents(str: string): string {
    if (str && typeof str === 'string') {
      return this.removeAccentsComplement(str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 'ØøðÐ', 'OoeD');
    }
    return '';
  }

  /**
   * Removes special characters
   * @param str string to be formatted
   */
  static removeSpecialCharacters(str: string) {
    if (str && typeof str === 'string') {
      return str.replace(/[^\w\s]/gi, '');
    } else {
      return str;
    }
  }

  static slice(array:any, start:any, end:any) {
    let length = array.length
    if (!length) {
      return []
    }
    start = start == null ? 0 : start
    end = end === undefined ? length : end

    if (start < 0) {
      start = -start > length ? 0 : (length + start)
    }
    end = end > length ? length : end
    if (end < 0) {
      end += length
    }
    length = start > end ? 0 : ((end - start) >>> 0)
    start >>>= 0

    let index = -1
    const result = new Array(length)
    while (++index < length) {
      result[index] = array[index + start]
    }
    return result
  }

  static chunk(array:any, size = 1) {
    size = Math.max(size, 0);
    const length = array == null ? 0 : array.length
    if (!length || size < 1) {
      return []
    }
    let index = 0
    let resIndex = 0
    const result = new Array(Math.ceil(length / size))

    while (index < length) {
      result[resIndex++] = this.slice(array, index, (index += size))
    }
    return result
  }

  /**
   * Truncar una cadena
   * {{ 'Hello World' | truncate: 4 }}
   * <!-- 'Hell' -->
   * {{ 'Hello World' | truncate: 4: '': true }}
   * <!-- 'Hello' -->
   * {{ 'Hello World' | truncate: 4: '...': true }}
   * <!-- 'Hello...' -->
   * {{ 'Hello World, how is it going?' | truncate: 14: '...': true }}
   * <!-- 'Hello World, how...' -->
   * @param input 
   * @param length 
   * @param suffix 
   * @param preserve 
   * @returns 
   */
  static truncate(input: string, length = 65, suffix = '...', preserve = false): any {

    if (!CheckTypeClass.isString(input)) {
      return input;
    }

    length = CheckTypeClass.isUndefined(length) ? input.length : length;

    if (input.length <= length) {
      return input;
    }

    preserve = preserve || false;
    suffix = suffix || '';
    let index = length;

    if (preserve) {
      if (input.indexOf(' ', length) === -1) {
        index = input.length;
      } else {
        index = input.indexOf(' ', length);
      }
    }

    return input.substring(0, index) + suffix;
  }
}
