import { GTMInteractionInterface } from "src/app/interfaces/gtm/gtm-interaction.interface";
/**
 * Datos default par taggear una interacción
 * interaction_category': 'home', 
 * interaction_action':'banner_principal',
 * interaction_label':'disfruta_mejor_experiencia_conoce_mas'
 */
export const GTMInteractionConstant: GTMInteractionInterface = {
    interaction_category: '',
    interaction_action: '',
    interaction_label: '', 
    event: 'Loyalty'
};
