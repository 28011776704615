import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { IDataObject } from '../interfaces/generic/i-data-object';
import { FrontConfigService } from '../services/front-config/front-config.service';
import { GatewayTokenService } from '../services/gateway-token/gateway-token.service';

/**
 * 
 * @param config Service que realiza la consulta de configracion.
 * @returns Observable con la consulta de parametros de configuracion.
 */
function initializeMetadata(config: FrontConfigService): () => Observable<IDataObject> {
    return () => config.initializeConfig();
}

function requestToken(gatewayTokenSingleton: GatewayTokenService) {
    return () => gatewayTokenSingleton.refreshGatewayToken();
}

@NgModule({
    providers: [
        // { provide: APP_INITIALIZER, useFactory: requestToken, deps: [GatewayTokenService], multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeMetadata, deps: [FrontConfigService], multi: true },
    ]
})
export class InitializerModule { }
