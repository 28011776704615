import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { sha256 } from 'js-sha256';
import { CryptoShaService } from './crypto-sha/crypto-sha.service';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { IRedirect } from './models/redirect.model';
import { HttpWebService } from '../http-web/http-web.service';
import { URL_SERVICES } from 'src/app/constants/constants';
import { map, Observable, of, tap } from 'rxjs';
import { IDataObject } from 'src/app/interfaces/generic/i-data-object';
import { IResponseWebService } from 'src/app/interfaces/response/i-response-web-service';
import { LAST_LOGIN, NAME, PBWC, SESSION, STATUS_LOGIN, URL_CONFIG, URLREDIRECTIDP } from 'src/app/constants/keys';

@Injectable({ providedIn: 'root' })
export class IdpService {

  REDIRECT: string = "redirect";

  KEY_VERIFIER = 'codeVerifier';

  CODE_IDP: string = 'code_idp';

  private targetFidelity: string = "_self";

  /**
   * Referencia de la ventana de autenticación de IDP
   */
  idpWindow!: Window;

  constructor(
    private cryptoShaService: CryptoShaService,
    private storage: LocalstorageService,
    private http: HttpWebService
  ) {
    // Constructor vacio.
  }

  public openWindow(redirect: IRedirect): void {
    const width = 1000;
    const height = 700;
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);

    const features: string = 'toolbar=no, location=no, directories=no, target=_self' +
      ' status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width
      + ', height=' + height + ', top=' + top + ', left=' + left;

    // TODO validar el funcionamiento sin el feature: const idpWindow: any = window.open('', '_self', features);
    const idpWindow: any = window.open('', '_self');
    this.initAuthentication(idpWindow, redirect);
  }

  private initAuthentication(idpWindow: Window, redirect: IRedirect): void {
    this.resetDataSession();
    const code: string = this.cryptoShaService.generateCode(2);
    this.storage.save(this.KEY_VERIFIER, code);
    this.storage.save(this.REDIRECT, redirect);
    const buffer: number[] = sha256.digest(code);
    const digest: number[] = buffer;
    const b64encoded: string = this.cryptoShaService.replaceCharacter(btoa(String.fromCharCode.apply(null, digest)));

    this.getConfigUrl().subscribe({
      next: (data: any) => {
        const idpWeb: string = data.mapConfig.url_idp;
        const clientCode: string = data.mapConfig.seralizer_id ? data.mapConfig.seralizer_id : environment.clientCode;
        const b64code = this.cryptoShaService.generateCode(1);

        const idpUrl: string = [idpWeb + 'IDPWeb/idp?response_type=code&client_id=' + clientCode + '&state=' + b64code + '&code_challenge=' + b64encoded].join(); //URL IDP configurable

        this.idpWindow = idpWindow;
        this.idpWindow.location.replace(idpUrl);
        // FIXME: Comentar estas líneas sólo son para emular idp login
        //setTimeout(() => {
         //this.idpWindow.location.replace('http://localhost:4200/identified?code=53037784&mock=true'); //usuario unique
         //this.idpWindow.location.replace('http://localhost:4200/identified?code=53058648&mock=true'); //usuario unique
        //this.idpWindow.location.replace('http://localhost:4200/identified?code=53183932&mock=true'); //usuario no unique
        // //   this.idpWindow.location.replace('http://localhost:4200/identified?error=server_error&error_description=Por%20el%20momento,%20el%20servicio%20no%20est%C3%A1%20disponible.%20Intente%20m%C3%A1s%20tarde.(1123661)&state=----HEdwLM---fZeuO--ai');
        //}, 5000);
      },
      error: (error: any) => {
        this.resetDataSession();
        // this.closeIdpWindow();
        this.refresIDP();
      }
    });
  }

  public resetDataSession(): void {
    [this.KEY_VERIFIER, this.REDIRECT, URL_CONFIG, this.CODE_IDP, NAME, LAST_LOGIN, SESSION, STATUS_LOGIN, PBWC]
      .forEach((property: string) => {
        this.storage.resetProperty(property);
      });
  }

  /**
   * 1.- Recupera referencia de la ventana de IDP.
   * 2.- Cierra la ventana de IDP.
   */
  public closeIdpWindow(): void {
    const idpWindow: any = window.open('', 'IDP', '');
    if (!!idpWindow) {
      idpWindow.close();
    }
  }

  public refresIDP(){
    const urlRetur = this.storage.get(URLREDIRECTIDP);
     window.open(`/web/${urlRetur}`, this.targetFidelity);
    //window.location.href = encodeURI(`/web/${urlRetur}`);
  }

  private getConfigUrl(): Observable<any> {
    const configUrl: any = this.storage.get(URL_CONFIG);
    return configUrl ? of(configUrl) : this.http.get(URL_SERVICES.urlConfiguratins)
      .pipe(

        map((httpResponse: IResponseWebService) => httpResponse.data),

        tap((data: IDataObject) => {
          this.storage.save(URL_CONFIG, data);
        })
      );
  }

}
