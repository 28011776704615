<section class="container-content py-3 container-footer" id="footer__unique">
    <div class="row_footer between-xs gap-space">

        <!-- primer columna -->
        <div class="col-xs-12 col-md-3 col-lg-3" [ngClass]="dataFooter.points.canShowPoints  && isHomePublic ? col4 : col3 ">
            <a [href]="redirectTo" *ngIf="redirectTo">
                <img [src]="dataFooter.logo.url" [alt]="dataFooter.logo.title">
            </a>
            <a *ngIf="!redirectTo">
                <img [src]="dataFooter.logo.url" [alt]="dataFooter.logo.title">
            </a>
        </div>

        <!-- Segunda columna -->
        <div class="col-xs-12 col-md-3 col-lg-3 logo-space container_space" [ngClass]="dataFooter.points.canShowPoints  && isHomePublic ? col4 : col3 ">
            <p class="footer__content__title"><strong>{{ dataFooter.termsConditions.title }}</strong></p>
            <ul class="container_space">
                <li *ngFor="let terms of dataFooter.termsConditions.list">
                    <ng-container *ngIf="terms.btnBanner?.type == 'INTERNAL'; else withoutRedirectTerms">
                        <a class="option_list" (click)="registerGTM(terms.title, dataFooter.termsConditions.title)" [href]="terms.btnBanner.destination" [target]="terms.btnBanner.target">
                            {{terms.title}}
                        </a>
                    </ng-container>

                    <ng-template #withoutRedirectTerms>
                        <a class="option_list" (click)="goOnClick(terms, dataFooter.termsConditions.title)">
                            {{terms.title}}
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>

        <!-- tercer columna -->
        <div class="col-xs-12 col-md-3 col-lg-3 logo-space container_space" *ngIf="dataFooter.points.canShowPoints  && isHomePublic" [ngClass]="dataFooter.points.canShowPoints  && isHomePublic ? col4 : col3 ">
            <p class="footer__content__title"><strong>{{ dataFooter.points.title }}</strong></p>
            <ul class="container_space">
                <li *ngFor="let terms of dataFooter.points.list">    
                    <ng-container *ngIf="terms.btnBanner?.type == 'INTERNAL'; else withoutRedirectPoints">
                        <a class="option_list" (click)="registerGTM(terms.title, dataFooter.points.title)" [href]="terms.btnBanner.destination" [target]="terms.btnBanner.target">
                            {{terms.title}}
                        </a>
                    </ng-container>

                    <ng-template #withoutRedirectPoints>
                        <a class="option_list" (click)="goOnClick(terms, dataFooter.points.title)">
                            {{terms.title}}
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>

        <!-- cuarta columna -->
        <div class="col-xs-12 col-md-3 col-lg-3 logo-space container_space" [ngClass]="dataFooter.points.canShowPoints  && isHomePublic ? col4 : col3 ">

            <p class="footer__content__title"><strong>{{ dataFooter.contact.title }}</strong></p>

            <div class="footer_contact_description">
                <p class="footer-contact__content__subTitle"> {{ dataFooter.contact.subTitle }} </p>

                <!-- <div class="footer_contact_description"> -->
                <div>
                    <img alt="image_phone" src="assets/icons/footer/phone.svg" />
                    <a class="footer-contact__content__santander" [href]="'tel:'+ numberTel" (click)="registerGTM(dataFooter.contact.title, dataFooter.contact.title)">
                        {{ dataFooter.contact.tel }}
                    </a>
                </div>

                <!-- <div class="footer_contact_description"> -->
                <div >
                    <p class="footer-contact__content__subTitle"> {{ dataFooter.contact.schedules }} </p>
                    <p class="footer-contact__content__subTitle"> {{ dataFooter.contact.schedulesS }} </p>
                </div>
            </div>
            <div class="footer_contact_social">
                <p class="footer-contact__content__title footer_contact_description"> 
                    {{ dataFooter.contact.socialMedia.title }} 
                </p>
                <section class="footer_contact_content_social_media">
                    <div class="footer_contact_content_social_media_item"
                        *ngFor="let item of dataFooter.contact.socialMedia.links">
                        <section class="section-img" *ngIf="item.iconImage" (click)="registerGtmRedes(item.link, dataFooter.contact.socialMedia.title)">
                            <img [src]="item.iconImage" [alt]="item.icon" >
                        </section>
                    </div>
                </section>
            </div>
        </div>

    </div>
</section>