import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICES } from 'src/app/constants/constants';
import { FIDELITY, GATEWAY_TOKEN, LAST_LOGIN, NAME, USERIDTAG, OPEN_DIALOG_FIDELITY, OPEN_DIALOG_NOT_FIDELITY, PBWC, SESSION, SIGNED_IN, SIGNED_OUT, STATUS_LOGIN, IDSECTIONFIDELITY, URLREDIRECTIDP } from 'src/app/constants/keys';
import { DatasourceService } from '../datasource/datasource.service';
import { HttpWebService } from '../http-web/http-web.service';
import { GatewayTokenService } from '../gateway-token/gateway-token.service';
import { IdpService } from '../idp/idp.service';
import { IRedirect } from '../idp/models/redirect.model';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { map } from 'rxjs';
import { IResponseWebService, ResponseFidelityToken } from 'src/app/interfaces/response/i-response-web-service';
import { Router } from '@angular/router';
import { FrontConfigService } from '../front-config/front-config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private REDIRECT: string = "redirect";

  private KEY: string = 'code_idp';

  private KEY_VERIFIER: string = 'codeVerifier';
  private targetFidelity: string = "_self";

  constructor(
    private storage: LocalstorageService,
    private httpWebService: HttpWebService,
    private gatewayTokenSingleton: GatewayTokenService,
    private source: DatasourceService,
    private idpService: IdpService,
    private router: Router,
    private frontConfig: FrontConfigService
  ) { }


  public login(redirect: IRedirect = { url: '/', type: 'internal', refresh: false, idSectionFidelity: '' }): void {
    
    this.idpService.openWindow(redirect);
    const timer = setInterval(() => {
      const status: string = this.storage.get(STATUS_LOGIN);
      if (status && status.includes(SIGNED_IN)) {
        clearInterval(timer);
        this.source.propagate(STATUS_LOGIN, SIGNED_IN);
        // this.redirectAfterLogin();
      }
    }, 1000)
  }

  public logout(): void {
    const session: string = this.storage.get('SESSION');
    const httpOptions: Map<string, string> = new Map([
      ["Content-Type", "application/json"],
      ["Idp", session],
    ]);

    this.httpWebService
      .postWithRawData(URL_SERVICES.logoutClient, new Map(), httpOptions)
      .subscribe({
        next: () => {
          this.redirectAfterLogout();
        },
        error: () => {
          this.redirectAfterLogout();
        }
      });
  }

  /**
   * Función para obtener los datos del cliente segúne el codigo que nos retorna IDP
   * @param idSectionFidelity es la configuración para realizar el redireccionamiento especifico hacia el portal privado. Se compone del IdSection | IdPremio
   */
  public initProccessToken(idSectionFidelity: string = ''): void {
        
    const body: Map<string, string> = new Map([
      ["opaqueToken", this.getCodeIdp()],
      ["codeVerifier", this.getCodeVerifier()]
    ]);

    const httpParams: Map<string, string> = new Map([
      ["newVersion", "true"]
    ]);

    this.httpWebService
      .postRaw(URL_SERVICES.loginClient, body, new Map(), httpParams)
      .subscribe({
        next: (httpResponse: HttpResponse<any>) => {
          const session: string = httpResponse.body.data.session;
          this.storage.save(NAME, httpResponse.body.data.name);
          this.storage.save(LAST_LOGIN, httpResponse.body.data.lastLogin);
          this.storage.save(SESSION, session);
          this.storage.save(USERIDTAG, httpResponse.body?.data?.userIDTag );
          this.storage.save(PBWC, httpResponse.headers.get(PBWC) ?? '');
          this.storage.save(STATUS_LOGIN, SIGNED_IN);
          this.storage.save(IDSECTIONFIDELITY, idSectionFidelity)

          this.validatStatus();
          // this.getTokenAndRedirect(idSectionFidelity);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.logout();
          this.idpService.resetDataSession();
          this.idpService.closeIdpWindow();
        }
      });
  }

  /**
   * Función para generar el token opaco con el que realizaremos el redirect hacia el portal privado
   * @param idSectionFidelity es la configuración para realizar el redireccionamiento especifico hacia Fidelity. Se compone del IdSection | IdPremio
   */
  getTokenAndRedirect(redirectPillar: string = ""): void {
    
    let urlToken!: string;

    let body: Map<string, string> = new Map([
      ["partyId", this.storage.get(PBWC)]
    ]);

    redirectPillar ? urlToken = `${URL_SERVICES.tokenEncode}/${redirectPillar}` : urlToken = `${URL_SERVICES.tokenEncode}`;

    this.httpWebService.postRaw(urlToken, body)
      .pipe(map((httpResponse: HttpResponse<any>) => httpResponse.body))
      .subscribe({
        next: (response: ResponseFidelityToken) => {
          const fidelity: string = this.frontConfig.getParams(FIDELITY).urldestination;
          this.targetFidelity = this.frontConfig.getParams(FIDELITY).target;
          const token: string = response.SecObjRec.SecObjInfo.SecObjValue;
          const destination: string = fidelity.replace("{token}", token);
          const redirect: IRedirect = {
            url: destination,
            type: 'fidelity'
          };
          this.storage.save(this.REDIRECT, redirect);
          this.redirectAfterLogin();
          // TODO indicamos si esta logeado o no
          // this.storage.save(STATUS_LOGIN, SIGNED_IN);
        },
        error: (errorResponse: HttpErrorResponse) => {
          const redirect: IRedirect = {
            url: '/',
            type: 'not-fidelity'
          };
          this.storage.save(this.REDIRECT, redirect);
          this.storage.save(STATUS_LOGIN, SIGNED_IN);
          this.redirectAfterLogin();
        }
      });
  }

  /**
   * Función para obtener los datos del cliente segúne el codigo que nos retorna IDP y posteriormente hacer el registro de una campaña
   * 
   */
  public validateToken(): void {
    const body: Map<string, string> = new Map([
      ["opaqueToken", this.getCodeIdp()],
      ["codeVerifier", this.getCodeVerifier()]
    ]);
 
    const httpParams: Map<string, string> = new Map([
      ["newVersion", "true"]
    ]);
 
    this.httpWebService
      .postRaw(URL_SERVICES.loginClient, body, new Map(), httpParams)
      // .pipe(map((httpResponse: IResponseWebService) => httpResponse.data))
      .subscribe({
        next: (httpResponse: HttpResponse<any>) => {
          this.storage.save(NAME, httpResponse.body.data.name);
          this.storage.save(LAST_LOGIN, httpResponse.body.data.lastLogin);
          this.storage.save(SESSION, httpResponse.body.data.session);
          this.storage.save(USERIDTAG, httpResponse.body.data.userIDTag);
          this.storage.save(PBWC, httpResponse.headers.get(PBWC) ?? '');
          // TODO validar si debemos hace el guardado de inicio de sesión en esta linea
          this.storage.save(STATUS_LOGIN, SIGNED_IN);
 
          this.validatStatus();
 
          if (this.storage.get(SESSION)) {
            this.refreshGatewayToken();
          }
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.logout();
          this.idpService.resetDataSession();
          this.idpService.closeIdpWindow();
        }
      });

  }

  /**
   * 
   * Función para validar el estatus de login y hacer el redireccionamiento hacia el home despues de obtener el codigo de IDP 
   */
  private validatStatus(){
    
    const timer = setInterval( () => {
      // Obtenemos el estatus del cliente de inicio de sesión
      const statusLogin: string = this.storage.get(STATUS_LOGIN);

      // Si el cliente inicio sesión hacemos un redireccionamiento hacia el punto inicial o donde se quizo iniciar sesión
      if( statusLogin ) {
        // Obtenemos la url de donde se inicio el proceso de autenticación
        const getUrlReturn = this.storage.get(URLREDIRECTIDP);
        this.router.navigateByUrl(`${getUrlReturn}`);
        clearInterval(timer);
      }
    }, 1000);
  }

  /**
   * Función para renovar el token y se actualiza en el storage
   */
  private refreshGatewayToken(): void {
    this.gatewayTokenSingleton
      .refreshGatewayToken().subscribe({
        next: (data: any) => {
          if (data) {
            this.storage.save(GATEWAY_TOKEN, data);
            this.storage.save(STATUS_LOGIN, SIGNED_IN);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.logout();
          this.idpService.resetDataSession();
          this.idpService.closeIdpWindow();
        }
      });
  }

  private redirectAfterLogin(): void {
    
    // this.idpService.closeIdpWindow();
    const redirect: IRedirect = this.getRedirect();
    if (redirect) {

      if (redirect.type === 'fidelity') {
        this.source.propagate(STATUS_LOGIN, SIGNED_OUT);
        this.source.propagate(FIDELITY, OPEN_DIALOG_FIDELITY);
      }

      else if (redirect.type === 'not-fidelity') {
        this.source.propagate(STATUS_LOGIN, SIGNED_OUT);
        this.logoutToken().then().finally(() => {
          this.idpService.resetDataSession();
          this.source.propagate(FIDELITY, OPEN_DIALOG_NOT_FIDELITY);
        });
      }

      else if (redirect.refresh) {
        window.location.reload();
      } else {
        this.router.navigate([redirect.url]);
      }
    } else {
      window.location.href = "/web/";
    }
  }

  public confirmRedirectToFidelity(): void {
    const redirect: IRedirect = this.getRedirect();
    this.logoutToken().then().finally(() => {
      this.storage.reset();
      window.open(redirect.url, this.targetFidelity);
      // window.location.href = redirect.url;
    });
  }

  redirectAfterLogout() {
    this.idpService.closeIdpWindow();
    this.storage.reset();
    window.location.reload();
  }


  public logoutToken(): Promise<any> {
    // this.store.dispatch(new ClickEventAction(this.getGAData()))
    const headers: Map<string, string> = new Map<string, string>([
      ["Content-Type", 'application/json'],
      ["Idp", this.storage.get('SESSION')]
    ]);
    return this.httpWebService.post(URL_SERVICES.logoutClient, new Map(), headers).toPromise();
  }

  public isAuthenticated(): boolean {
    const status: string = this.storage.get(STATUS_LOGIN);
    return status ? status.includes(SIGNED_IN) : false;
  }

  public closeIdpWindow(): void {
    // this.idpService.closeIdpWindow();
    this.idpService.refresIDP();
  }

  public setCodeIdp(codeIdp: string): void {
    this.storage.save(this.KEY, codeIdp);
  }

  public getCodeIdp(): string {
    return this.storage.get(this.KEY);
  }

  public setCodeVerifier(codeVerifier: string): void {
    this.storage.save(this.KEY_VERIFIER, codeVerifier);
  }

  public getCodeVerifier(): string {
    return this.storage.get(this.KEY_VERIFIER);
  }

  public setRedirect(redirect: IRedirect): void {
    this.storage.save(this.REDIRECT, redirect);
  }

  public getRedirect(): IRedirect {
    return this.storage.get(this.REDIRECT);
  }

  /**
   *
   * @returns
   */
  getUserIDTag() {
    return this.storage.get(USERIDTAG);
  }

}
