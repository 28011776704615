import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CryptoShaService {

  constructor() {
    // TODO document why this constructor is empty
  }

  /**
   * 
   * Método generador de state de IDP 
   * @param type 
   * @returns 
   */
  generateCode(type: number = 0) {

    if (type === 1) {
      return this.newrandexp(21, 32, ["-"]);//(/^[A-Za-z0-9-]{20,32}$/g).gen();
    }

    return this.newrandexp(44, 128, [".", "_", "~"]);//(/^[A-Za-z0-9-._~]{43,128}$/g).gen();
  }

  /**
   * Generate a string that matches with a pattern and has a lenght between a min and a max value
   * @param a min value
   * @param b max value
   * @param characters pattern
   */
  newrandexp(a: number, b: number, characters: string | any[]) {
    let chainLength = this.randInt(a, b);
    let randomChain = "";
    for (let i = 0; i < chainLength - 1; i++) {
      let num = this.randInt(1, 3);
      switch (num) {
        case 1:
          let special = this.randInt(0, characters.length - 1);
          randomChain += characters[special];
          break;
        case 2:
          let special2 = this.randInt(65, 90);
          randomChain += String.fromCharCode(special2);
          break;
        case 3:
          let special3 = this.randInt(97, 122);
          randomChain += String.fromCharCode(special3);
          break;
        default:
          let special5 = "" + this.randInt(0, 9);
          randomChain += special5;
          break;
      }
    }
    return randomChain;
  }

  /**
   * Genera y devuelve aleatoriamente un número entre a y b (inclusive)
   * @param a 
   * @param b 
   * @returns 
   */
  randInt(a: number, b: number) {
    return Math.floor(Math.random() * (b - a + 1)) + a;
  }

  /**
   * 
   * Método que reemplaza los símbolos '+' por '-' y '/' por '_'
   * @param str 
   * @returns 
   */
  replaceCharacter(str: string) {
    let replace = str.replace(/\+/g, "-");

    let replace2 = replace.replace(/\//g, "_");
    return replace2;
  }

}
