import { ButtonBanner } from "../banner/i-banner-data.model";

export interface Logo {
    title: string;
    url: string;
}

export interface List {
    title: string;
    idSectionFidelity: string;
    btnBanner: ButtonBanner;
}

export interface PointsTerms {
    title: string;
    canShowPoints?: boolean;
    list: List[];
}

export interface Link {
    title: string;
    link: string;
    target: string;
    extra: string;
}

export interface Links {
    icon: string;
    iconImage: string;
    link: Link;
}

export interface SocialMedia {
    title: string;
    links: Links[];
}

export interface Contact {
    title: string;
    subTitle: string;
    icon: string;
    tel: number;
    schedules: string;
    schedulesS: string;
    socialMedia: SocialMedia;
}

export interface SpaceColumn {
    column3: string;
    column4: string;
}

export interface Footer {
    logo: Logo;
    termsConditions: PointsTerms;
    points: PointsTerms;
    contact: Contact;
    pathsHomePublic: string[];
    spaceColumn: SpaceColumn
}



