import { Component, ViewEncapsulation } from '@angular/core';
import { Footer, Link, Links, PointsTerms } from 'src/app/interfaces/footer/footer.model';
import { FrontConfigService } from 'src/app/services/front-config/front-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs';
import { FOOTER_UNIQUE } from 'src/app/constants/keys';
import { GtmService } from 'src/app/services/googletagmanager/gtm.service';
import { UrlConfigService } from 'src/app/services/urlConfig/url-config.service';
import { termsConditionsDummy, pointsDummy } from './footer.conf';

@Component({
  selector: 'unique-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {

  dataFooter!: Footer;
  isHomePublic: boolean = false;
  redirectTo: string = "";
  numberTel!: number;
  col3!: string;
  col4!: string;
  termsConditionsDumm: PointsTerms = termsConditionsDummy;
  pointsDumm: PointsTerms = pointsDummy
  routeNavigate!: string;

  constructor( private confServ: FrontConfigService,
               private router: Router,
               private gtmSrv: GtmService,
               private urlConfigSrv: UrlConfigService) {
    this.getContentData();
    this.initRoutes();
    this.routeNavigate = this.router.url;
  }

  getContentData(){
    this.redirectTo = this.confServ.getParams(FOOTER_UNIQUE).logo.redirectTo;
    let telephone = this.confServ.getParams(FOOTER_UNIQUE).contact.tel;
    this.numberTel = Number( telephone.split(/\s+/).join('') );
    
    this.dataFooter = {
      ...this.confServ.getParams(FOOTER_UNIQUE)
    };
    
    this.col3 = this.confServ.getParams(FOOTER_UNIQUE).spaceColumn.column3;
    this.col4 = this.confServ.getParams(FOOTER_UNIQUE).spaceColumn.column4;
    
  }

  initRoutes() {
    this.router.events.pipe(
      filter( event => event instanceof NavigationEnd ),
      map( event => event as NavigationEnd )
    ).subscribe({
      next: (data: NavigationEnd) => {
        const current: string = data.urlAfterRedirects ?? "";
        const paths: string[] = this.confServ.getParams(FOOTER_UNIQUE).pathsHomePublic;
        this.isHomePublic = false;
        paths.every((path: string) => {
          this.isHomePublic = current.includes(path);
          return !current.includes(path);
        });
      }
    });
  }

  registerGTM( item: string, seccion: string ){
    this.gtmSrv.interaction('unique rewards', `seccion_footer_${seccion}`, `${item}`, '', this.routeNavigate);
  }

   /**
   * Metodo para realizar redirect a paginas externas, sitio privado de fidelity o no hace alguna acción
   * @param evt Datos de pagina.
   */
   public goOnClick(evt: PointsTerms | any, seccion: string): void {
    this.registerGTM(evt, seccion);
    if (evt.idSectionFidelity) {
      this.urlConfigSrv.goToFidelity('', evt);
    } else if (evt.btnBanner.type) {
      this.urlConfigSrv.goToUrlExternal(evt.btnBanner);
    } else {
      return;
    }
  }

  public registerGtmRedes(event: Link, seccion: string){
    this.registerGTM(event.title, seccion);
    window.open(event.link);
  }

}