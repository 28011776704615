import { IModalPublic } from "src/app/interfaces/dialog-modal/dialog.model";
import { IBenefitsRaw, IInvitationRaw, IMyPoints, IStepsData } from "src/app/interfaces/home/home.model";


export const initBtnBanner = {
    destination: '',
    target: '',
    type: '',
    extra: ''
}

/**
 * Valores por default para seccion Beneficios.
 */
export const defaultBenefits: IBenefitsRaw = {
    titleSection: 'Descubre todos los beneficios a los que puedes acceder con tus Unique Points.',
    cards: []
};

/**
 * Valores por default para seccion My Points.
 */
export const defaultMyPoints: IMyPoints = {
    title: '¿Dónde puedo utilizar mis puntos?',
    subtitle: 'Usa tus puntos en los comercios participantes',
    brands: [],
    idSectionFidelity: '',
    btnBanner: initBtnBanner
};

/**
 * Valores por default para seccion Invitacion.
 */
export const defaultInvitation: IInvitationRaw = {
    title: '¿Aún no tienes tu tarjeta?',
    subtitle: 'Si aún no tienes tu tarjeta, solicítala y disfruta del mundo Santander Unique',
    btnTitle: 'Solicítala',
    btnUrl: '',
    btnType: '',
    urlImg: 'assets/imgs/banner.png',
    idSectionFidelity: '',
    btnBanner: initBtnBanner
};

/**
 * Valores por default para seccion Steps.
 */
export const defaultSteps: IStepsData = {
    title: '¿Ya cuentas con una tarjeta Unique Rewards?',
    subtitle: 'Acceder es muy sencillo, solo debes seguir lossiguientes tres pasos:',
    bgImage: 'assets/imgs/banner.png',
    steps: []
};

export const defaultModalHome: IModalPublic = {
    type: 'NONE',
    isVisibleVideo: 'NOK',
    status: false,
    bgImages: [],
    urlGif: '',
    urlVideo: '',
    title: '',
    subtitle: '',
    txtContent: '',
    button: {
        type: "NONE",
        text: '',
        destination: '',
        target: '_self',
        extra: ''
    },
    extras: [],
    extraClass: '' //w-small
};

