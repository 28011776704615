import { Component } from '@angular/core';

@Component({
  selector: 'root',
  templateUrl: './unique-front.component.html'
})
export class UniqueFrontComponent {

  constructor() {
    // TODO document why this constructor is empty
  }

}
