import { IImageData } from "src/app/interfaces/banner/i-image-data.model";

export class BannerUtils {

    /**
     * Recupera la url del background correspondiente a la dimesion de la pantalla.
     * 
     * @param size Dimension de la pantalla. 
     * @param images Array de elementos de tipo IImageData.
     * @returns url de la imagen correspondiente.
     */
    public static getBackgroundImage(size: number, images: IImageData[] = []): string {
        const labelGrid: string = this.getLabelGrid(size);
        return this.getImageBySize(labelGrid, images);
    }

    /**
     * Recupera la url de la imagen correspondiente al tamano indicado.
     * 
     * @param labelGrid Label del tamano.
     * @param images Lista de imagenes.
     * @returns string con la url.
     */
    private static getImageBySize(labelGrid: string, images: IImageData[] = []): string {
        const bgcurrent: IImageData | undefined = images.find((ibg: IImageData) => ibg.type === labelGrid);
        return bgcurrent ? bgcurrent.url : "";
    }

    /**
     * Valida tamano de la pantalla y selecciona el label adecuado.
     * 
     * @param size Dimension de la pantalla.
     * @returns label correspondiente a la dimesion de la pantalla.
     */
    private static getLabelGrid(size: number) {
        if (size <= 500) {
            return "xs";
        } else if (size > 500 && size <= 750) {
            return "sm";
        } else if (size > 750 && size <= 900) {
            return "md";
        } else {
            return "lg";
        }
    }
}