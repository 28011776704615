/**
 * Varifica tipos de datos
 */
export class CheckTypeClass {

    /**
     * Valida si una cadena cumple el formato JSON
     * @param json Cadena a validar
     * @returns True si cumple el formato False no cumple el formato
     */
    static isJson(json: string): boolean {
        try {
            JSON.parse(json);
        } catch (e) {
            return false;
        }

        return true;
    }

    /**
     * Valida si es Undefined
     * @param value Valor
     */
    static isUndefined(value: any): value is undefined {
        return typeof value === 'undefined';
    }

    /**
     * Valida si es Null
     * @param value Valor
     */
    static isNull(value: any): value is null {
        return value === null;
    }

    /**
     * Valida si es Number
     * @param value Valor
     */
    static isNumber(value: any): value is number {
        return typeof value === 'number';
    }

    /**
     * Valida si es NumberFinite
     * @param value Valor
     */
    static isNumberFinite(value: any): value is number {
        return this.isNumber(value) && isFinite(value);
    }

    // Not strict positive
    /**
     * Valida si es Positive
     * @param value Valor
     */
    static isPositive(value: number): boolean {
        return value >= 0;
    }

    /**
     * Valida si es Integer
     * @param value Valor
     */
    static isInteger(value: number): boolean {
        // No rest, is an integer
        return (value % 1) === 0;
    }

    /**
     * Valida si es Nil
     * @param value Valor
     */
    static isNil(value: any): value is (null | undefined) {
        return value === null || typeof (value) === 'undefined';
    }

    /**
     * Valida si es String
     * @param value Valor
     */
    static isString(value: any): value is string {
        return typeof value === 'string';
    }

    /**
     * Valida si es Object
     * @param value Valor
     */
    static isObject(value: any): boolean {
        return typeof value === 'object';
    }

    /**
     * Valida si es Array
     * @param value Valor
     */
    static isArray(value: any): boolean {
        return Array.isArray(value);
    }

    /**
     * Valida si es Function
     * @param value Valor
     */
    static isFunction(value: any): boolean {
        return typeof value === 'function';
    }
}
