import { Injectable } from '@angular/core';
import { ButtonBanner, IBannerData } from 'src/app/interfaces/banner/i-banner-data.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { IBtnBanner } from 'src/app/interfaces/interiors/i-interiors.model';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { URLREDIRECTIDP } from 'src/app/constants/keys';

@Injectable({
  providedIn: 'root'
})
export class UrlConfigService {

  constructor( private authSrv: AuthenticationService,
               private router: Router,
               private storageSrv: LocalstorageService ) { }

  public goToFidelity( url: string, data: any ) {
    const getUrl = this.router.url;
    const urlDestination: string = !!url ? url : getUrl;
    const idSectionFidelity = data.idSectionFidelity;
    this.storageSrv.save(URLREDIRECTIDP, getUrl);
    this.authSrv.login({ url: urlDestination, type: 'fidelity', refresh: true, idSectionFidelity })
  }

  public goToUrlExternal(data: IBtnBanner) {
    window.open(data.destination, data.target);
  }

}
