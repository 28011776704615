import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DatasourceService {

  private map: Map<string, any> = new Map<string, any>();

  /**
   * Emisor de eventos que permite el flujo de informacion. Se debe realizar la subscricion a este elemento para recibir las actualizaciones.
   * 
   * ```ts
   * ds.changes.subscribe((changes: Map<string, any>) => {
   *    console.log('Mapa con los cambios: ', changes);
   * });
   * ```
   */
  @Output() changes: EventEmitter<Map<string, any>> = new EventEmitter();

  constructor() {
    // TODO document why this constructor is empty
  }

  /**
   * Propaga las actualizaciones de información.
   * 
   * @param key Clave con la que se propaga la informacion.
   * @param value Valor que sera propagado.
   */
  public propagate(key: string, value: any): void {
    this.map.set(key, value);
    this.changes.emit(this.map);
  }

  /**
   * Propaga las actualizaciones de información a traves de un mapa.
   * 
   * @param all Lista de tipo clave, valor con actualizaciones.
   */
  public propagateAll(all: Map<string, any>): void {
    for (const [key, value] of all) {
      this.map.set(key, value);
    }
    this.changes.emit(this.map);
  }

}
