import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DatasourceService } from 'src/app/services/datasource/datasource.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { SIGNED_IN, NAME, LAST_LOGIN, STATUS_LOGIN, HEADER_UNIQUE, HOME_PUBLIC, CAMPAIGN, IDSECTIONFIDELITY, URLREDIRECTIDP, REDIRECT } from 'src/app/constants/keys';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';
import { FrontConfigService } from 'src/app/services/front-config/front-config.service';
import { HeaderItem, SubMenuList } from './header.model';
import { filter, map } from 'rxjs';
import { GtmService } from 'src/app/services/googletagmanager/gtm.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UrlConfigService } from 'src/app/services/urlConfig/url-config.service';



@Component({
  selector: 'unique-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

  public isUserSignIn: boolean = false;
  public showBtnIdp: boolean = true;
  public userName: string = "";
  public lastLogin: string = "";
  public abbreviation: string = "";
  public styleDisplay: string = "none";
  public menuList: HeaderItem[] = [];
  public menuListMovil: HeaderItem[] = [];
  public showBtnLogin: boolean = true;
  public isHomePublic: boolean = false;
  public logo: string = '';
  public textLogin: string = "";
  public textLogout: string = "";
  public sidebarVisible: boolean = false;
  public showList: boolean = false;
  public textMobil: string = "";
  public textMobilSinEtiquetas: string = "";
  public subMenu!: SubMenuList | any;
  public pathHome: string = "";
  public pathCampaing: string = "";
  public sizeWindow: boolean = false;
  public isHome: boolean = false;
  public routerPathNav!: string;

  private handlerChanges = (changes: Map<string, any>) => {
    const status: string = changes.get(STATUS_LOGIN);
    this.setValues(status);
  }

  constructor(
    private authentication: AuthenticationService,
    private source: DatasourceService,
    private storage: LocalstorageService,
    private router: Router,
    private frontConfig: FrontConfigService,
    private userActivity: UserActivityService,
    private gtmSrv: GtmService,
    private menuSubjectSrv: AlertService,
    private urlConfigSrv: UrlConfigService ) {

    source.changes.subscribe(this.handlerChanges);

    // Set logo
    this.logo = this.frontConfig.getParams(HEADER_UNIQUE).logo ?? 'assets/imgs/header/brand_santander_unique.svg';

    // Se inicia escucha para cambio de rutas.
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    ).subscribe({
      next: (data: NavigationEnd) => {
        
        this.validatePathHome(data.url);        

        const current: string = data.urlAfterRedirects ?? "";
        const paths: string[] = this.frontConfig.getParams(HEADER_UNIQUE).pathsHomePublic;
        this.isHomePublic = false;
        paths.every((path: string) => {
          this.isHomePublic = current.includes(path);
          return !current.includes(path);
        });
        this.initHeader(this.isHomePublic ? HOME_PUBLIC : CAMPAIGN);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.calcSizeForMobil();
  }

  ngOnInit(): void {
    const status: string = this.storage.get(STATUS_LOGIN);
        
    this.validatePathHome(this.router.url);
    this.setValues(status);
    this.validateLogin();
  }

  getClass() {
    let clase: string;
    
    if( this.showBtnLogin ){
      this.showList ? clase = 'topNavListHome' : clase = 'navListNone';
    } else {
      this.showList ? clase = 'navList' : clase = 'navListNone';
    }

    return clase;
  }

  /**
   * Validamos si el path es diferente al /home publico, para ocultar o mostrar el botón de "Ingresar"
   */
  validatePathHome(url: string) {
    // console.log({url});
    if ( url.includes('/home/pilar') || url.includes('/oferta-de-tarjetas') || url.includes('/legal') || url.includes('/preguntas-frecuentes') ) {
      // console.log(url, false);
      this.isHome = false;
    } else {
      // console.log(url, true);
      this.isHome = true;
    }
  }

  private validateLogin(){
    const isLogin = this.authentication.isAuthenticated();
    const hostName = window.location.href;
    const typeRedirect = this.storage.get(REDIRECT);
    
    this.validatePathHome(hostName);
    // this.validatePathHome(this.router.url);
    
    if(isLogin && typeRedirect.type == 'fidelity') {
      const idSectionFidelity = this.storage.get(IDSECTIONFIDELITY);
      this.authentication.getTokenAndRedirect(idSectionFidelity);
      
    }
    // entramos aquí si venimos de campañas al home o a alguna otra sección de legales
    if(isLogin && typeRedirect.type != 'fidelity' && ( hostName.includes('web/home') || hostName.includes('web/oferta-de-tarjetas') || hostName.includes('web/legal') || hostName.includes('web/preguntas-frecuentes')) ) {
      this.authentication.logout();
    }

  }

  private initHeader(type: string): void {
    const raw: HeaderItem[] = this.frontConfig.getParams(HEADER_UNIQUE).nav;
    const listSubMenu: SubMenuList = this.frontConfig.getParams(HEADER_UNIQUE).subMenu;

    this.pathCampaing = this.frontConfig.getParams(HEADER_UNIQUE).campaing;
    this.pathHome = this.frontConfig.getParams(HEADER_UNIQUE).home;
    this.showBtnLogin = this.frontConfig.getParams(HEADER_UNIQUE).showBtnLogin;
    this.menuList = raw.filter((item: HeaderItem) => (item.type === type) && ( item.label !== 'Inicio' )  );
    
    this.menuListMovil = raw.filter( ( item: HeaderItem ) => item.type === type );
    
    this.subMenu = listSubMenu.type == type ? listSubMenu : {}
    if ( !!this.subMenu.label && this.subMenu.list.length > 0 ) {
      this.menuListMovil = this.menuListMovil.concat(this.subMenu.list);
    }

    this.textLogin = this.frontConfig.getParams(HEADER_UNIQUE).textLogin;
    this.textLogout = this.frontConfig.getParams(HEADER_UNIQUE).textLogout;
    this.textMobil = this.frontConfig.getParams(HEADER_UNIQUE).textMobile;
  }

  private setValues(status: string): void {
    if (status && status === SIGNED_IN && this.router.url.includes('campaign')) {

      const fullname: string = this.storage.get(NAME);

      this.isUserSignIn = status.includes(SIGNED_IN);
      this.showBtnIdp=false;
      this.lastLogin = this.storage.get(LAST_LOGIN).slice(0, -3) + " hrs.";
      this.userName = fullname.length > 0 ? fullname : "Cliente Unique";
      this.abbreviation = this.getAbbr(fullname);
      this.userActivity.setUp();
    }else if(status && status === SIGNED_IN){
      this.showBtnIdp=false;
    }else {
      this.isUserSignIn = false;
      this.lastLogin = "";
      this.userName = "";
      this.abbreviation = "";
      this.showBtnIdp=true;
    }
  }

  private getAbbr(fullname: string): string {
    if (fullname.length === 0) {
      return "NA";
    }
    const fnSplit: string[] = fullname.split(' ');
    return fnSplit.reduce((total, currentValue, currentIndex) => {
      if (currentIndex < 2) {
        return total + currentValue.charAt(0);
      } else {
        return total;
      }
    }, "");
  }

  public handlerClick = (event: any) => {
    const styleClass: string = event.target.getAttribute('name');
    if (
      (styleClass && styleClass.includes("session__login")) ||
      (styleClass && styleClass.includes("session__logout"))
    ) {
      this.styleDisplay = "block";
    } else {
      this.styleDisplay = "none";
      document.removeEventListener("click", this.handlerClick);
    }
  };

  public showDataSession(): void {
    this.styleDisplay = "block";
    setTimeout(() => {
      document.addEventListener('click', this.handlerClick);
    }, 100);
  }

  public login(isMovil: boolean = false): void {
    const urlIDP = this.router.url;
    // console.log({urlIDP});
    
    if(isMovil){ this.showList = false; }
    this.authentication.login({ url: '/', type: 'fidelity', refresh: true });
    this.storage.save(URLREDIRECTIDP, urlIDP);
    this.gtmSrv.interaction('unique_rewards', 'seccion_menu', `${this.textLogin}`, '', this.router.url);
  }

  public logout(): void {
    this.authentication.logout();
  }

  public redirectHome() {
    const hostName = window.location.href;

    if ((hostName.includes('web/home') && !hostName.includes('web/home/pilar')) || hostName.includes('web/campaign/landing')) {
      return window.location.reload();
    }

    if (this.isHomePublic) {
      return window.open(this.pathHome, '_self');
    } else if (!this.isHomePublic) {
      return window.open(this.pathCampaing, '_self');
    }

  }

  saveGtm(itemNav: HeaderItem) {
    this.gtmSrv.interaction('unique_rewards', 'seccion_menu', `${itemNav.label}`, '', this.router.url);
  }

  statusChangeMenuHamburger() {
    this.showList = !this.showList;    
    this.menuSubjectSrv.setMenuSubject = this.showList;
  }

  /**
   * Metodo para realizar redirect a paginas externas, sitio privado de fidelity o no hace alguna acción
   * @param evt Datos de pagina.
   */
  public goOnClick(evt: HeaderItem | SubMenuList | any, isMovil: boolean = false): void {
    this.saveGtm(evt);
    if(isMovil){ this.showList = false; }

    if (evt.idSectionFidelity) {
      this.urlConfigSrv.goToFidelity(this.pathHome, evt);
    } else if (evt.btnBanner?.type) {
      this.urlConfigSrv.goToUrlExternal(evt.btnBanner);
    } else {
      return;
    }
  }

  calcSizeForMobil() {
    let size = window.innerWidth;
    if( size > 650 && size <= 1040 ) {
      this.sizeWindow = true;
      let delteP = this.frontConfig.getParams(HEADER_UNIQUE).textMobile.replace(/<p>/g, ' ');
      this.textMobilSinEtiquetas = delteP.replace(/<\/\p>/g, ' ');
    } else {
      this.sizeWindow = false;
    }
    if( size > 1040 ) {
      this.showList = false;
    }
  }

}
