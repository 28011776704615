import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MODAL_CARD_ERROR, MODAL_GENERIC_ERROR, MODAL_SUCCES } from 'src/app/constants/keys';
import { IDataModal } from 'src/app/interfaces/dialog-modal/dialog.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { GtmService } from 'src/app/services/googletagmanager/gtm.service';
import { AlertService } from '../../../services/alert/alert.service';

@Component({
  selector: 'unique-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AlertComponent implements OnInit {

  abriModal: boolean = false;
  dataModal!: IDataModal;
  isTyc: boolean = false;

  constructor(private alertSrv: AlertService, private authServ: AuthenticationService, private gtmServ: GtmService) { }

  ngOnInit(): void {
    this.changeData();
  }

  // Funcion que detecta los cambios en los componentes y así abrir el modal.
  changeData() {
    this.alertSrv.getAlertSubject.subscribe((rspModal: any) => {
      this.abriModal = rspModal?.openModal ? rspModal.openModal : false;
      this.dataModal = rspModal?.dataModal ? rspModal.dataModal : null;
      this.isTyc = rspModal?.dataModal.isTyc ? rspModal?.dataModal.isTyc : false;
    });
  }

  // Funcion que detecta los cambios en los componentes y así cierra el modal.
  closeModal(modal: boolean) {
    if (this.dataModal.typeModal === MODAL_SUCCES) {
      this.gtmServ.interaction( `${this.dataModal.campaign}`, `modal_${this.dataModal.title}`, `${this.dataModal.textBtn}` );
    }
    if (this.dataModal.typeModal === MODAL_CARD_ERROR || this.dataModal.typeModal === MODAL_GENERIC_ERROR ) {
      this.gtmServ.interaction(`${this.dataModal.campaign}`, `modal_${this.dataModal.title}`, `${this.dataModal.textBtn}`);
      if( !!this.dataModal.urlButton ) {
        window.open(this.dataModal.urlButton, this.dataModal.target);
      }
      this.authServ.logout();
    }
    this.abriModal = modal;
    this.alertSrv.setAlertSubject = null;
  }

  justCloseModal(modal: boolean) {

    if (this.dataModal.typeModal === MODAL_SUCCES) {
      this.gtmServ.interaction(`${this.dataModal.campaign}`, `modal_${this.dataModal.title}`, `cerrar`);
    }
    if (this.dataModal.typeModal === MODAL_CARD_ERROR || this.dataModal.typeModal === MODAL_GENERIC_ERROR) {
      this.gtmServ.interaction(`${this.dataModal.campaign}`, `modal_${this.dataModal.title}`, `cerrar`);
      this.authServ.logout();
    }
    this.abriModal = modal;
    this.alertSrv.setAlertSubject = null;
  }
}
